.modal {
  width: 500px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.circledesignstyle {
  .out {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border: 6px solid var(--Success-50, #ecfdf3);
    background: var(--Success-100, #dcfae6);
    padding: 6px;
  }
}
.inputBox,
.inputBox:focus {
  color: var(--Gray-900, #101828);

  /* Body large */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.08px;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: #fff;
  box-shadow: none !important;
  outline: none !important;
}

.tablePrimeBar {
  td {
    height: 56px;
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.12px;
  }
}
