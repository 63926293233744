.noDataBox {
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 20px;
  background-image: url("../../assets/images/img/Background\ pattern\ decorative.svg");
}
.searchImg {
    width: 104px;
    height: 104px;
    position: absolute;
    bottom: -15px;
    left: 30%;
}

.title {
  color: var(--Gray-900, #101828);
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.75px;
  margin-bottom: 16px;
  margin-top: 50px;
}

.subTitle {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.18px;
  margin-bottom: 24px;
}

.supportext {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
