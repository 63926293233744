.filterBox {
  display: flex;
  padding: var(--spacing-sm, 2px) var(--spacing-md, 2px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.menu {
  width: 229px;
  padding: 4px 4px;
  border-radius: 4px;
}

.newButton,
.newButton:hover,
.newButton:focus {
  gap: 8px;
  display: flex;
  padding: 4px 14px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
}

.total {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

.totalCount {
  color: var(--Primary-500, #1ab2ff);
  text-align: center;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  letter-spacing: 0.12px;
  border-radius: 16px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
}


.totalMoney {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: Inter;
  font-style: normal;
  margin-bottom: 0px;
  letter-spacing: 0.08px;
  color: rgb(7, 148, 85);
}

.clientImg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 10px;
  border: 0.75px solid #ccc;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
}

.ellipsis {
  white-space: nowrap;
  width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location {
  width: 36px;
  height: 36px;
  display: flex;
  padding: var(--spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Primary-200, #e9d7fe);
  background: var(--Gray-50, #f9fafb);
}

.active {
  background: #ebf8ff !important;
}

.selectedCount {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  color: var(--Primary-500, #1ab2ff);
}

.deletedRow td {
  background-color: #fffbfa !important;
}

.paidRow td {
  background-color: #ecfdf3 !important;
}

.unpaidRow td {
  background-color: #fef3f2 !important;
}

.partialPaidRow td {
  background-color: #fffaeb !important;
}

.fontStanderdSize {
  align-items: center;
  display: flex;
  font-family: Inter;
  color: var(--Success-900, #074d31);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  .paid {
    color: var(--Success-700, #067647);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-radius: 16px;
    border: 1px solid var(--Success-200, #abefc6);
    background: #ecfdf3;
    padding: 4px 8px 4px 8px;   
    svg {
      fill: #079455;
    }
    .plusIcon::after {
      content: "";
      left: 0px;
      top: -5px;
      height: 27px;
      position: absolute;
      border-left: 1px solid #abefc6;
    }
  }

  .unpaid {
    color: var(--Warning-700, #b54708);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-radius: 16px;
    border: 1px solid var(--Warning-200, #fedf89);
    background: var(--Warning-50, #fffaeb);
    padding: 4px 8px;
    svg {
      fill: #d92d20;
    }
    .plusIcon::after {
      content: "";
      left: 0px;
      top: -5px;
      height: 27px;
      position: absolute;
      border-left: 1px solid #fedf89;
    }
  }

  .partialPaid {
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-radius: 16px;
    padding: 4px 8px;
    color: var(--Error-700, #b42318) !important;
    border: 1px solid var(--Error-200, #fecdca) !important;
    background: var(--Error-50, #fef3f2) !important;
    svg {
      fill: #d92d20;
    }
    .plusIcon::after {
      content: "";
      left: 0px;
      top: -5px;
      height: 27px;
      position: absolute;
      border-left: 1px solid #fecdca;
    }
  }
}

.status.active {
  width: fit-content;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px)
    var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  gap: var(--spacing-xs, 4px);
  color: var(--Success-700, #067647);
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 16px;
  border: 1px solid var(--Success-200, #abefc6);
  background: var(--Success-50, #ecfdf3);
}

.status.inactive {
  width: fit-content;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px)
    var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  gap: var(--spacing-xs, 4px);
  color: #b42318;
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 16px;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}

.actionButton {
  font-size: 14px !important;
  padding: 8px 14px !important;
}

.shakeText {
  display: inline-block;
  animation: tilt-n-move-shaking 0.85s infinite;
}

@keyframes tilt-n-move-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

.allInvoice {
  gap: 8px;
  display: flex;
  font-size: 14px;
  color: #424242;
  font-weight: 600;
  padding: 6px 16px;
  border-radius: 4px;
  background: #fff;
  align-items: center;
  border-radius: 40px;
  justify-content: center;
  border: 1px solid var(--Gray-300, #d0d5dd);
}

.unpaidInvoice {
  gap: 8px;
  display: flex;
  font-size: 14px;
  color: #b42318;
  font-weight: 600;
  padding: 6px 16px;
  border-radius: 4px;
  align-items: center;
  border-radius: 40px;
  justify-content: center;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}