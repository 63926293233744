.filterBox {
  display: flex;
  padding: var(--spacing-sm, 2px) var(--spacing-md, 2px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.menu {
  width: 229px;
  padding: 4px 4px;
  border-radius: 4px;
}

.newButton,
.newButton:hover,
.newButton:focus {
  gap: 8px;
  display: flex;
  font-size: 14px;
  padding: 4px 14px;
  border-radius: 4px;
  align-items: center;
  border-radius: 40px;
  justify-content: center;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc) !important;
}

.total {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

.totalCount {
  color: var(--Primary-500, #1ab2ff);
  text-align: center;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  letter-spacing: 0.12px;
  border-radius: 16px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
}

.totalMoney {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: Inter;
  font-style: normal;
  margin-bottom: 0px;
  letter-spacing: 0.08px;
  color: rgb(7, 148, 85);
}

.clientImg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 10px;
  border: 0.75px solid #ccc;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
}

.ellipsis {
  white-space: nowrap;
  width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location {
  width: 36px;
  height: 36px;
  display: flex;
  padding: var(--spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Primary-200, #e9d7fe);
  background: var(--Gray-50, #f9fafb);
}

.active {
  background: #ebf8ff !important;
}

.selectedCount {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  color: var(--Primary-500, #1ab2ff);
}

.deletedRow td {
  background-color: #fffbfa !important;
}

.paidRow td {
  background-color: #ecfdf3 !important;
}

.unpaidRow td {
  background-color: #fef3f2 !important;
}

.fontStanderdSize {
  align-items: center;
  display: flex;
  font-family: Inter;
  color: var(--Success-900, #074d31);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  svg {
    fill: #074d31;
  }
  .plusIcon {
    background: #f6fef9;
    border-radius: 16px;
    border: 1px solid var(--Success-200, #a9efc5);
    cursor: pointer;
    display: inline-flex;
    margin-left: 8px;
    padding: 1px;
  }
  .paid-false {
    color: var(--Error-900, #7a271a);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    svg {
      fill: #d92d20;
    }
    .plusIcon {
      background: #f6fef9;
      border-radius: 16px;
      border: 1px solid var(--Error-200, #fecdca);
      background: var(--Error-50, #fef3f2);
      cursor: pointer;
      display: inline-flex;
      margin-left: 8px;
      padding: 1px;
    }
  }
}

.status.active {
  width: fit-content;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px)
    var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  gap: var(--spacing-xs, 4px);
  color: var(--Success-700, #067647);
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 16px;
  border: 1px solid var(--Success-200, #abefc6);
  background: var(--Success-50, #ecfdf3);
}

.status.inactive {
  width: fit-content;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px)
    var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  gap: var(--spacing-xs, 4px);
  color: #b42318;
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 16px;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}

.actionButton {
  font-size: 14px !important;
  padding: 8px 14px !important;
}

.allMoneyButton,
.allMoneyButton:hover,
.allMoneyButton:focus {
  gap: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 16px;
  border-radius: 4px;
  align-items: center;
  border-radius: 40px;
  justify-content: center;
  color: #424242 !important;
  background: #fff !important;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
}

.unpaidMoneyButton,
.unpaidMoneyButton:hover,
.unpaidMoneyButton:focus {
  gap: 8px;
  display: flex;
  font-size: 14px;
  color: #b42318;
  font-weight: 600;
  padding: 6px 16px;
  border-radius: 4px;
  align-items: center;
  border-radius: 40px;
  justify-content: center;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}
