.box {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerBox {
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.spinnerSize {
    width: 30px;
    height: 30px;
}