.userSettingPage {
  .userHead {
    p {
      color: var(--Gray-600, #475467);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.14px;
      span {
        color: var(--Primary-600, #158ecc);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
  .users_userHead {
    h2 {
      margin-bottom: 0;
    }
  }
  .contentwrapmain {
    margin-top: 0;
  }
  .ellipsis {
    margin-left: 10px;
  }
  tr {
    td {
      color: var(--Gray-700, #344054);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.14px;
    }
  }
  .userImg {
    border-radius: 19px;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }
  .privilege {
    border-radius: 16px;
    border: 1px solid var(--Success-200, #abefc6);
    background: var(--Success-50, #ecfdf3);  
    padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
    color: var(--Success-700, #067647);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    white-space: nowrap;
  }
  .contentMenuTab {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    .addUserBut {
      border-radius: var(--radius-xs, 40px);
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--Base-White, #fff);
      padding: 8px 18px;
      color: var(--Gray-700, #344054);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
  .showDeleteBut {
    color: #b42318 !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    border-radius: 40px !important;
    border: 1px solid var(--Error-300, #fda29b) !important;
    background: #fff !important;
    padding: var(--spacing-md, 8px) 14px !important;
  }
}
#appSettingPage {
  .showDeleteBut {
    color: #b42318 !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    border-radius: 40px !important;
    border: 1px solid var(--Error-300, #fda29b) !important;
    background: #fff !important;
    padding: var(--spacing-md, 8px) 14px !important;
  }
  .appaction {
    button {
      color: var(--Primary-600, #158ecc);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      background: transparent;
      border: 0;
      padding: 0;
      &:hover {
        color: var(--Primary-600, #158ecc);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        background: transparent;
        border: 0;
        padding: 0;
      }
    }
  }
  .connected {
    border-radius: 16px;
    border: 1px solid var(--Success-200, #abefc6);
    background: var(--Success-50, #ecfdf3);
    padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
    color: var(--Success-700, #067647);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .invited {
    color: var(--Blue-700, #175cd3);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    display: flex;
    padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--Blue-200, #b2ddff);
    background: var(--Blue-50, #eff8ff); 
  }

  .disconnected {
    color: var(--Error-700, #b42318);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    display: flex;
    padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--Error-200, #fecdca);
    background: var(--Error-50, #fef3f2);  
  }
}

.selectuserInput {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  padding: 0px 14px;
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.modal {
  width: 659px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}
.circledesignstyle {
  .out {
    border-radius: 28px;
    border: 8px solid var(--Success-50, #ecfdf3);
    background: var(--Success-100, #dcfae6);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 6px;
  }
}

.lable {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
}

.inputText {
  width: 100%;
  padding: 10px 14px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: none !important;
  outline: none !important;
  font-family: Inter;
  color: var(--Gray-900, #101828) !important;
}

.lable {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
}

.inputText {
  width: 100%;
  padding: 10px 14px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: none !important;
  outline: none !important;
  font-family: Inter;
  color: var(--Gray-900, #101828) !important;
}

.error {
  border: 1px solid red !important;
}

.td {
  padding: 12px 16px !important;
  background: #f9fafb !important;
  border-right: 1px solid var(--Gray-200, #eaecf0) !important;
}

tr:hover .td {
  background: #ebf8ff !important;
}

.hoverShow,
.hoverShow:hover {
  background: transparent !important;
}

tr:hover .hoverShow {
  visibility: visible !important;
}

.borderNone {
  border-bottom: 0px solid #fff !important;
}

.dangerTextButton,
.dangerTextButton:hover {
  color: var(--Error-700, #b42318) !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background: transparent !important;
}

.fileUploadBox {
  display: flex;
  padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs, 4px);
  align-self: stretch;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  margin: 16px 0px;
}

.uploadedImgBox {
  width: 40px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
}

.uploadedText1 {
  color: #475467;
  font-size: 14px;
}

.uploadedText2 {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--Primary-700, #106b99);
}

.lable {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
}

.successTextButton,
.successTextButton:hover {
  color: var(--Error-700, #067647) !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background: transparent !important;
}


.leftBoxParent {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Gray-50, #f9fafb);
}

.leftbox {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 9999px);
  background: var(--Gray-100, #f2f4f7);
}

.leftboxText {
  color: #344054;

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.leftBoxParent.active {
  border: 1px solid var(--Primary-300, #76d1ff);
  background: var(--Primary-50, #ebf8ff);

  .leftbox {
    background: var(--Primary-100, #bae8ff);
  }

  .leftboxText {
    color: var(--Primary-700, #106b99);
  }
}


