.filterBox {
  display: flex;
  padding: var(--spacing-sm, 2px) var(--spacing-md, 2px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.newButton,
.newButton:hover,
.newButton:focus {
  gap: 8px;
  width: 59px;
  height: 28px;
  display: flex;
  font-size: 14px;
  padding: 4px 14px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid var(--Primary-600, #158ecc) !important;
  background: var(--Primary-600, #158ecc) !important;
}

.total {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

.totalCount {
  background: #fffaeb;
  border: 1px solid #fff0c1;
  border-radius: 16px;
  color: #ffc301;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.12px;
  line-height: 22px;
  text-align: center;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
}

.ellipsis {
  white-space: nowrap;
  width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location {
  width: 36px;
  height: 36px;
  display: flex;
  padding: var(--spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Primary-200, #e9d7fe);
  background: var(--Gray-50, #f9fafb);
}

.active {
  background: #ebf8ff !important;
}

.selectedCount {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  color: var(--Primary-500, #1ab2ff);
}

.nameBgBox {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #dedede;
}

.serviceTag {
  color: var(--Gray-700, #344054);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
}

.serviceName {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}
