.gobackBtn {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: var(--spacing-md, 8px) 14px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
  border-radius: 40px;
}

.filterBox {
  display: flex;
  padding: var(--spacing-sm, 6px) var(--spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  height: 32px;
  width: 32px;
}

.active {
  background: #ebf8ff !important;
}

.selectedCount {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  color: var(--Primary-500, #1ab2ff);
}

.clientName {
  color: #344054;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lost {
  margin: auto;
  width: 81px;
  color: var(--Warning-700, #b54708);
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Warning-200, #fedf89);
  background: var(--Warning-50, #fffaeb);
}

.complete {
  margin: auto;
  width: fit-content;
  color: var(--Success-700, #067647);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Success-200, #a9efc5);
  background: var(--Success-50, #ecfdf3);
}

.declined {
  margin: auto;
  width: fit-content;
  color: var(--Error-700, #b42318);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}

.inprogress {
  margin: auto;
  width: fit-content;
  color: var(--Gray-700, #026AA2);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Gray-300, #B9E6FE);
  background: var(--Gray-25, #F0F9FF);
}

.defaultStatus {
  margin: auto;
  width: fit-content;
  color: var(--Gray-700, #344054);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Gray-25, #fcfcfd);
}

.lostProfit {
  margin-left: auto;
  width: fit-content;
  color: var(--Warning-800, #93370d);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Warning-300, #fec84b);
  background: var(--Warning-100, #fef0c7);
}

.inprogressProfit {
  margin-left: auto;
  width: fit-content;
  color: var(--Success-800, #026AA2);
  text-align: center;

  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #B9E6FE);
  background: var(--Gray-25, #F0F9FF);
}

.completeProfit {
  margin-left: auto;
  width: fit-content;
  color: var(--Success-800, #085d3a);
  text-align: center;

  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Success-300, #75e0a7);
  background: var(--Success-100, #dcfae6);
}

.declinedProfit {
  margin-left: auto;
  width: fit-content;
  color: var(--Error-800, #912018);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Error-300, #fda29b);
  background: var(--Error-100, #fee4e2);
}

.department {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0px;
}

.subdepartment {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0px;
}

.orderHeading {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.historyTitle {
  color: var(--Text-Dark-Gray, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.historyText {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  white-space: pre-line;
}

.historyDescription {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
}
