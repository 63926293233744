.headerprofile {
  a {
    display: flex;
    align-items: center;
  }
}

.trialNote {
  top: 0;
  width: 100%;
  height: 30px;
  color: #fff;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  position: sticky;
  background: #1ab2ff;
  position: relative;
}

.navbarActionIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  margin: 0px !important;
  justify-content: center;
  display: flex !important;
}

.navbarActionIcon:hover {
  background: var(--Primary-25, #F2FAFF);
}