.modal {
  width: 607px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 10px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.inputBox {
  width: 100%;
  border-radius: 4px;
  padding: 10px 14px;
  margin-bottom: 10px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid var(--Gray-300, #d0d5dd);
}

.inputBox.error {
  margin-bottom: 0px;
  border-color: var(--Red-500, #e53e3e);
}

.label {
  color: #475467;
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 22px;
  margin-bottom: 6px;
}

.password-strength {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.progress-container {
  display: flex;
  width: 100%;
  max-width: 480px;
  margin: 10px 0;
}

.progress-bar {
  flex: 1;
  height: 8px;
  background-color: #e0e0e0;
  margin: 0 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.progress-bar.green {
  background-color: #4caf50;
}

.strength-text {
  color: var(--Gray-600, #475467);

  /* Body small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  letter-spacing: 0.12px;
}
