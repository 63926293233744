.chatContainer {
  display: flex;
}

.chatSidebar {
  flex: 1 0 0;
  height: 100%;
  display: flex;
  max-width: 360px;
  background: #fff;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid var(--Gray-200, #eaecf0);
}

.chartSidebarHeader {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
}

.chartSidebarHeaderFirstRow {
  width: 100%;
  display: flex;
  padding: 20px 24px;
  align-items: center;
  justify-content: space-between;
}

.unreadChatCount {
  gap: 4px;
  display: flex;
  padding: 2px 6px;
  border-radius: 6px;
  background: #fff;
  height: fit-content;
  align-items: center;
  border: 1px solid var(--Gray-300, #d0d5dd);

  span {
    position: relative;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: var(--Gray-700, #344054);
  }
}

.chatSidebarTitle {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0px;
}

.chatSidebarArchived {
  margin: 0px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.175px;
}

.chartSidebarHeaderSecondRow {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 24px 12px 24px;
  justify-content: space-between;
}

.chartSidebarHeaderThirdRow {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-25, #fcfcfd);
}

.chartSidebarHeaderUserCount {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);

  span {
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  div {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Gray-50, #f9fafb);
    color: var(--Gray-700, #344054);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.15px;
  }
}

.chartSidebarHeaderProjectCount {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  span {
    color: var(--Gray-500, #667085);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  div {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Gray-50, #f9fafb);
    color: var(--Gray-700, #344054);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.15px;
  }
}

.chartSidebarUsers {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.chartSidebarUserContainer {
  width: 100%;
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
}

.chartSidebarUserContainer:hover {
  background: var(--Primary-25, #f2faff);
}

.userAvatar {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-50, #f9fafb);
}

.chatUserName {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.lastMessageTime {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.chatArea {
  flex: 1 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
}

.chatEmptyText {
  color: var(--Gray-700, #344054);
  text-align: justify;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.selectedChatArea {
    height: 100%;
    display: flex;
    flex-direction: column;
}