.newJobTag {
  color: var(--Primary-800, #0a4766);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  display: flex;
  padding: var(--spacing-xs, 4px) var(--spacing-xl, 10px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-100, #bae8ff);
  background: var(--Primary-50, #ebf8ff); 
}

.border {
  border: 1px solid #f2f4f7 !important;
}

.borderBottom {
  border-bottom: 1px solid #f2f4f7 !important;
}

.background {
  background: #fbfcfd;
}

.customLabel {
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin-bottom: 8px;
}

.text {
  color: var(--Gray-600, #475467);

  /* Body large bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.chooseUserBox {
  display: flex;
  padding: var(--spacing-lg, 12px) var(--spacing-xl, 16px);
  align-items: center;
  gap: var(--spacing-xl, 16px);
  flex: 1 0 0;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Primary-200, #eaecf0);
  background: var(--Primary-50, #fff);
}

.heading {
  color: var(--Gray-600, #475467);

  /* Title body */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  margin-bottom: 0px;
}

.paymentType {
  &.typeBorder {
    border-top: 1px solid #eaecf0;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .paymentmain {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6px;
    span {
      margin: 0 24px;
      color: var(--Gray-600, #475467);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.14px;
    }
  }
  .customRadio {
    // Hide the original radio button
    opacity: 0;
    position: absolute;
    width: 138px;
    height: 44px;

    // Create a custom radio button
    + label {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 16px;
      user-select: none;
    }

    // Create the circle (custom radio button)
    + label {
      position: absolute;
      width: 138px;
      height: 44px;
      background: transparent;
      transition: all 0.3s ease;
      border-radius: var(--radius-xs, 4px);
      display: flex;
      align-items: center;
      padding: 10px 18px;
      justify-content: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
    }

    + label.fix {
      color: #1d2939;
      border: 1px solid #98b15b;
    }

    &:checked + label.fix {
      background: #e1fc9d;
      border: 1px solid #e1fc9d;
    }

    + label.hours {
      color: #5b5813;
      border: 1px solid #d0ca63;
    }

    &:checked + label.hours {
      background: #f9f49a;
      border: 1px solid #f9f49a;
    }

    + label.timetracker {
      color: #51256e;
      border: 1px solid #c295e0;
    }

    &:checked + label.timetracker {
      color: #1d2939;
      background: #e8ccfb;
      border: 1px solid #e8ccfb;
    }

    + label.shift,
    + label.timeFrame {
      color: #344054;
      border: 1px solid #d0d5dd;
    }

    &:checked + label.shift,
    &:checked + label.timeFrame {
      color: #344054;
      background: #f2faff;
      border: 1px solid #bae8ff;
    }
  }

  .radioLabel {
    margin-left: 0px; // Adjust spacing between the radio button and label text
  }

  .RadioButton {
    width: 138px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.paymentBox {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
}

.dollarBox {
  width: 28px;
  height: 28px;
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--Primary-50, #ebf8ff);
}

.jobGalleri img {
  border-radius: 8px;
}

.viewBox {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 8px solid var(--Success-50, #ecfdf3);
  background: var(--Success-100, #dcfae6);
}

.viewHeading {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.defaultStatus {
  font-size: 12px;
  padding: 2px 6px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  border-radius: 16px;
  border: 1px solid #eaecf0;
  background: #f9fafb;
}

.status {
  font-size: 12px;
  padding: 2px 6px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 16px;
}

.open {
  color: #065b76;
  border: 1px solid #a9d6ef;
  background: #ecf7fd;
}

.Accepted {
  color: #067611;
  border: 1px solid #c4efa9;
  background: #f2fdec;
}

.CONFIRMED {
  color: #067647;
  border: 1px solid #a9efc5;
  background: #ecfdf3;
}

.ASSIGN {
  color: #520676;
  border: 1px solid #dda9ef;
  background: #f6ecfd;
}

.NotConfirmed {
  color: #b42318;
  border: 1px solid #fecdca;
  background: #fef3f2;
}

.COMPLETED {
  color: #067647;
  border: 1px solid #a9efc5;
  background: #ecfdf3;
}

.DECLINED {
  color: #b42318;
  border: 1px solid #fecdca;
  background: #fef3f2;
}

.MANAGER_DECLINED {
  color: #b54708;
  border: 1px solid #fedf89;
  background: #fffaeb;
}

.finished {
  font-size: 12px;
  padding: 2px 6px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  border-radius: 16px;
  border: 1px solid #e9d7fe;
  background: #f9fafb;
}

.assign {
  font-size: 12px;
  padding: 2px 6px;
  line-height: 18px;
  font-weight: 500;
  color: var(--Error-700, #b42318);
  border-radius: 16px;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}

.modal {
  width: 800px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.attachmentBox {
  width: 246px;
  height: 48px;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  justify-content: space-between;
  border-radius: 6px;
  background: var(--Gray-100, #f2f4f7);
}
