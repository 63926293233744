.modal {
  width: 607px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 10px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.title {
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.75px;
  margin-bottom: 16px;
}

.historyBox {
  width: 170px;
  display: flex;
  padding: 13px 15px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--Gray-100, #f2f4f7);

  a {
    color: var(--Gray-700, #344054);

    /* Body default */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.14px;
    text-decoration: none;
  }
}
